:root {
  --primary: #323338;
  --primary-bg-color: #ffffff;
  --secondary: #297ccf;
  --secondary-text: rgba(0, 0, 0, 0.98);
  --bg-surface-color: #eceff8;
  --font-family: 'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  --border-radius-medium: 8px;
  --surface-box-shadow-xs: 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  --menuIcon-background-color: #f6f7fb;
  --rtDrawer-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  --table-shadow: 0px 1px 3px rgba(33, 150, 243, 0.3);
  --border-left-table: 6px solid #579bfc;
  --chip-gap: 1px solid #929ffc;
  --user-name-clr: #371097eb;
  --cus-name: #5138d5e8;
  --status-clr: #df2f4a;
  --flash-br: #e3dddda8;
  --export-clr: #008000;
  --notify-clr: #7f7d7d;
}

.modal-head {
  display: flex;
  justify-content: space-between;
  background-color: var(--secondary);
  color: var(--primary-bg-color);
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 10px;
  font-size: 16px;
}

.task-detail .MuiGrid-item {
  padding-top: 10px !important;
}
.task-detail .MuiOutlinedInput-root {
  padding: 0px !important;
}
.task-detail .MuiAutocomplete-tag {
  margin: 2px !important;
    height: 24px;
    font-size: 11px;
}
.task-detail .MuiChip-label{
  padding-left: 8px;
  padding-right: 8px;
}
.discount {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  text-align: right;
}
.discount .MuiOutlinedInput-input {
  padding: 5px 5px !important;
  font-size: 14px;
}
.discountTable {
  display: flex;
  justify-content: flex-end;

}
.discountTable-align {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  text-decoration: underline;
  width: 430px !important;
}
.discountTable .MuiIconButton-root {
  padding: 4px !important;
}

.total-align {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  width: 15% !important;
  margin-right: 25px;
}

.netAmount-align {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  width: 40% !important;
  margin-right: 25px;
}

.lead-details .MuiGrid-item {
  padding-left: 12px !important;
  padding-top: 14px !important;
}
.lead-details .MuiOutlinedInput-input {
  padding: 5px 5px !important;
  font-size: 14px;
}
.lead-details .MuiOutlinedInput-root {
  padding: 0px !important;
}
.client-details .MuiGrid-item {
  padding-left: 12px !important;
  padding-top: 12px !important;
}
.client-details .MuiOutlinedInput-input {
  padding: 5px 5px !important;
  font-size: 14px;
}
.client-details .MuiOutlinedInput-root {
  padding: 0px !important;
}
.modules .MuiChip-root {
  height: 26px !important;
}
.lead-project {
  height: 86vh;
  overflow-y: auto;
  padding-left: 110px;
  padding-right: 110px;
}
.add-task {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
}
.add-lead {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
}

.cus-task {
  display: flex;
  justify-content: center;
  padding: 4px;
  background-color: var(--secondary);
  color: var(--primary-bg-color);
}

.card .MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

.card-descript {
  padding-top: 8px !important;
  font-size: 14px !important;
}
.card .MuiIconButton-root {
  padding: 0px !important;
}
.descript {
  padding-bottom: 10px;
  font-size: 13px !important;
}
.descriptDate {
  padding-top: 0px !important;
  font-size: 12px !important;
}
.notydesc {
  padding-top: 4px !important;
  padding-bottom: 3px !important;
  font-size: 12px !important;
  color: var(--user-name-clr);
}
.cus-name {
  /* padding-top: 4px !important; */
  font-size: 12px !important;
  color: var(--cus-name);
  font-weight: 700 !important;
}
.user-name {
  padding-top: 0px !important;
  font-size: 12px !important;
  color: var(--user-name-clr);
}

.type {
  padding-top: 4px !important;
  font-size: 10px !important;
}
.type .MuiSvgIcon-root {
  font-size: 16px !important;
}
.aligntext {
  display: flex;
  justify-content: space-between;
}
.detail-drawer {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 15px;
}
.detail-drawer .MuiGrid-item {
  padding-top: 6px !important;
}

.meeting .MuiInput-root {
  padding: 0px !important;
  font-size: 14px !important;
}

.meeting .MuiInput-input {
  padding: 0px !important;
  font-size: 14px !important;
}
.project-drawer .MuiGrid-item {
  padding-top: 6.5px !important;
}

.home {
  height: 86vh;
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  border-right: var(--chip-gap);
}
.exportGrid .MuiIconButton-root{
  padding-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 8px;
}
.home-notifySide {
  height: 86vh;
  overflow-y: auto;
  padding-bottom: 20px;
}
.add-btn {
  margin-right: 20px;
  border-right: var(--chip-gap);
}
.chip {
  display: flex;
  justify-content: center;
  border-right: var(--chip-gap);
}
.badgeClass .MuiBadge-badge {
  border: 1px solid var(--primary-bg-color);
  background-color: var(--status-clr);
  color: var(--primary-bg-color);
  top: 6% !important;
  right: 6% !important;
  height: 21px !important;
}
.badgeClass .MuiChip-root {
  height: 20px;
  font-size: 10px;
  padding: 6px 6px;
}
.chipClass .MuiChip-root {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 24px;
  font-size: 11px;
  padding: 6px 6px;
}

.zone-chip {
  display: flex;
  justify-content: center;
}
.search-field {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.exportDiv {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.exportBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 6px;
}
.exportBox .MuiSvgIcon-root {
  color: var(--export-clr) !important;
}
.exportBox .MuiTypography-root {
  color: var(--export-clr) !important;
  font-size: 10px;
}
.MuiOutlinedInput-input.Mui-disabled{
  opacity: 1;
    -webkit-text-fill-color: rgba(0, 0, 0, 0.76) !important;
}
.react-confirm-alert-overlay {
  background-color: #ffffff3d !important;
}

/* Customize the body of the confirm alert */
.react-confirm-alert-body {
  font-family: var(--font-family) !important;
  background-color: var(--primary-bg-color) !important;
  border: 1px solid #a4999945 !important;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.29) !important;
}

.react-confirm-alert-body {
  h1 {
    margin-top: 0px !important;
    margin-bottom: 14px;
  }
}

/* Customize button styles */
.react-confirm-alert-button-group {
  font-family: var(--font-family) !important;
  margin-top: 12px !important;
}

.notification-card .MuiCardContent-root {
  padding: 2px 6px !important;
}
.notification-card .type {
  padding-top: 0px !important;
}
.notification-card .notifyType {
  color: var(--notify-clr) !important;
  font-size: 14px !important;
  margin-top: 5px !important;
  margin-left: 6px !important;
}
.notify-count .MuiBadge-badge {
  background-color: var(--status-clr);
  color: var(--primary-bg-color);
}
.chipalign{
  display: flex;
  justify-content: center;
  align-items: center;
}
.filterSupChip .MuiChip-label{
  padding-left: 0px !important;
}
.filterSupChip .MuiSvgIcon-root{
  font-size: 1.0rem !important;
 }
.filterSupChip .MuiIconButton-root{
  color: var(--primary-bg-color);
}
.flash-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  border-radius: 10px;
  padding: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: var(--primary-bg-color); /* Background color */
  border: 1px solid var(--flash-br);
}

.flash-message {
  animation: flashMove 400s linear infinite; /* Adjust the speed here */
}

@keyframes flashMove {
  0% {
    transform: translateX(100%);
    visibility: visible;
  }

  100% {
    transform: translateX(-100%);
  }
}
@media screen and (max-width: 1500px) {
  .filterSupChip .MuiChip-label{
    padding-right: 0px !important;
  }
  .filterSupChip .MuiIconButton-root{
    padding: 0px !important;
  }
}
@media screen and (max-width: 1300px) {
  .lead-project {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 16px;
  }
  .lead-details .MuiGrid-item {
    padding-left: 10px !important;
    padding-top: 10px !important;
  }
  .client-details .MuiGrid-item {
    padding-left: 10px !important;
    padding-top: 10px !important;
  }
}

@media screen and (max-width: 900px) {
  .add-btn {
    margin-right: 20px;
    border-right: none;
  }
  .chip {
    display: flex;
    justify-content: right;
    border-right: none;
  }

  .zone-chip {
    display: flex;
    justify-content: right;
  }
  .lead-project {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 16px;
  }
  .lead-details .MuiGrid-item {
    padding-left: 8px !important;
    padding-top: 8px !important;
  }
  .client-details .MuiGrid-item {
    padding-left: 8px !important;
    padding-top: 8px !important;
  }
}

@media screen and (max-width: 450px) {
  .add-btn {
    margin-right: 20px;
    border-right: none;
  }
  .modal-head {
    padding-top: 0px;
    padding-bottom: 1px;
    font-size: 14px;
  }
  .chip {
    display: flex;
    justify-content: center;
    border-right: none;
  }

  .zone-chip {
    display: flex;
    justify-content: center;
  }
  .detail-drawer {
    padding-top: 0px;
  }
  .lead-project {
    padding-left: 20px;
    padding-right: 20px;
  }
  .lead-details .MuiGrid-item {
    padding-left: 8px !important;
    padding-top: 6px !important;
  }
  .lead-details .MuiOutlinedInput-input {
    font-size: 12px;
  }
  .client-details .MuiGrid-item {
    padding-left: 8px !important;
    padding-top: 6px !important;
  }
  .client-details .MuiOutlinedInput-input {
    font-size: 12px;
  }
  .card-descript {
    padding-top: 0px !important;
    font-size: 12px !important;
  }
  .card .MuiCardContent-root{
    padding: 10px;
  }
  .card .MuiCardContent-root:last-child {
    padding-bottom: 10px !important;
  }
  .descript {
    padding-bottom: 6px;
    font-size: 12px !important;
  }
  .discount .MuiOutlinedInput-input{
    font-size: 12px;
  }
  .discountTable{
    padding-left: 16px;
  }
  .add-task {
    padding-left: 0px;
    padding-right: 0px;
  }
  .add-lead {
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media screen and (max-width: 590px) {
  .home {
    border-right: none;
  }
}
